<template>
  <div class="ButtonsInitCallback mt-3">
    <button class="btn btn-block btn-primary btn-lg ripple-surface" data-mdb-toggle="modal" data-mdb-target="#ModalCallback" @click="$parent.callback.title='Заказ звонка'">Заказать звонок</button>
   </div>
</template>

<script>

//import * as mdb from 'mdb-ui-kit'; // lib

export default {
  name: 'ButtonsInitCallback',
  props: {},
  methods:{
    /*
    showModal: (modalID) => {
      alert (modalID)
      const ModalEl = document.getElementById(modalID)
      const modal = new mdb.Modal(ModalEl)
      modal.show()
    }
    */
  }
}
</script>

<style scoped lang="scss">

</style>
