<template>
  <div class="header">
    <div class="d-block my-3 text-center">
      <a :href="'https://www.instagram.com/'+contacts.insta.name" class="ripple ripple-surface ripple-surface-white" target="_blank">
        <img class="logo rounded-circle" src="/assets/img/premium_logo.jpg" alt="">
      </a>
      <p class="text-center mt-3">
      <a :href="'tel:'+contacts.phone" class="text-dark">{{contacts.phone}}</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    contacts: Object
  }
}
</script>

<style scoped lang="scss">
.logo{
  max-width: 125px;
}
</style>
