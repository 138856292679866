<template>
  <div class="footer mt-3">
    <p class="text-uppercase small text-center mb-0">Строительство премиальных бассейнов</p>
    <p class="text-center">
      <a class="text-uppercase" href="https://premium-pool.com" target="_blank">premium-pool.com</a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {}
}
</script>

<style scoped lang="scss">

</style>
