<template>
  <div class="ButtonsLinks pt-3">
    <div class="row gx-2">
      <div class="col-12">
        <a href="https://premium-pool.com" class="btn btn-block btn-light btn-lg ripple-surface h-100 d-flex justify-content-center align-items-center" target="_blank">
          <span>Перейти на сайт</span>
        </a>
      </div> 
    </div>
  </div>
</template>

<script>

//import * as mdb from 'mdb-ui-kit'; // lib

export default {
  name: 'ButtonsLinks',
  props: {},
  methods:{
    /*
    showModal: (modalID) => {
      alert (modalID)
      const ModalEl = document.getElementById(modalID)
      const modal = new mdb.Modal(ModalEl)
      modal.show()
    }
    */
  }
}
</script>

<style scoped lang="scss">

</style>
