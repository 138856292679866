<template>
  <div id="app" class="container">
    <div class="row h-100">
      <div class="col-12 col-md-6 offset-md-3 col-xl-4 offset-xl-4 d-flex flex-column">
        <Header :contacts="contacts"></Header>
        <Social :contacts="contacts"></Social>
        <transition name="fade" mode="out-in">
          <ButtonsInitCallback v-if="!callback.sended"></ButtonsInitCallback>
        </transition>
        <ButtonsLinks></ButtonsLinks>      
        <div class="flex-grow-1"></div>
        <Footer></Footer>  
      </div>
    </div>


    <ModalCallback :param="callback" @sended="callback.sended=true"></ModalCallback>
    <!-- <ModalForm @send_form="send_form($event)"></ModalForm> -->

  </div>
</template>

<script>
import Header from './components/Header.vue'
import Social from './components/Social.vue'
import ButtonsInitCallback from './components/ButtonsInitCallback.vue'
import ModalCallback from './components/ModalCallback.vue'
import ButtonsLinks from './components/ButtonsLinks.vue'
import Footer from './components/Footer.vue'
// import ModalForm from './components/ModalForm.vue'

import * as mdb from 'mdb-ui-kit'; // lib
import { Input } from 'mdb-ui-kit'; // module

import 'mdb-ui-kit/css/mdb.min.css';
//import 'themify-icons/themify-icons/_themify-icons.scss';

export default {
  name: 'App',
  data: function() {
    return {
        callback:{
          title:'Заказ звонка',
          sended:false,
          form_data: {}
        },
        contacts:{
          phone: "8(800)775-19-79",
          insta: {
            name:"premium_pool_"
          },
          whatsapp: "79064639798",
          telegram: "PremiumPoolCom"          
        }
    } 
  },
  methods:{
    send_form: function(e){
      this.callback.title = e.form_title
      this.callback.form_data = Object.assign({},e)

      const ModalEl = document.getElementById('ModalCallback')
      const modal = new mdb.Modal(ModalEl)
      modal.show()
      //console.log (e)
    }
  },
  components: {
    Header, Social, ButtonsInitCallback, ModalCallback, ButtonsLinks, Footer /*, ModalForm */
  }
}
</script>

<style lang="scss">
html{
 // background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(83,196,253,1) 91%);
 background: linear-gradient(0deg, #dbe6f0 50%, rgba(255,255,255,1) 91%);
 height:100%;
 display: flex;
}

body{
  // background-color: #c9eeff;
  //background-image: url("/assets/img/bg.jpg");
  height:100%;
  width:100%;
  background-color: transparent;
  background-position: top center, bottom center;
  background-repeat: repeat-x, repeat-x;
  background-image: url('/assets/img/pp_top_bg.png'), url('/assets/img/pp_bottom_bg.png') ;
    
}

a{
  outline:none!important;
}
#app {
  height:100%;
}

.fade-enter-active, .fade-leave-active { transition: opacity .25s; } .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ { opacity: 0; }
</style>
