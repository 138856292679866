<template>
  <div class="social py-3">
    <div class="row g-4">
      <div class="col-6 text-end">
        <a :href="'tel:'+contacts.phone" class="btn-social ripple ripple-surface ripple-surface-white" target="_blank">
          <img src="/assets/img/tel.png" alt="">
        </a>
      </div>
      <div class="col-6">
        <a :href="'sms:+'+contacts.whatsapp" class="btn-social ripple ripple-surface ripple-surface-white" target="_blank">
          <img src="/assets/img/sms.png" alt="">
        </a>
      </div>
      <div class="col-6 text-end">
        <a :href="'https://wa.me/'+contacts.whatsapp" class="btn-social ripple ripple-surface ripple-surface-white" target="_blank">
          <img src="/assets/img/w.png" alt="">
          <span class="has-animation-blink">
          </span>
        </a>        
      </div>
      <div class="col-6">
        <a :href="'https://t.me/'+contacts.telegram" class="btn-social ripple ripple-surface ripple-surface-white" target="_blank">
          <img src="/assets/img/t.png" alt="">
          <span class="has-animation-blink">
          </span>
        </a>        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Social',
  props: {
    contacts: Object
  }
}
</script>

<style scoped lang="scss">
.logo{
  max-width: 150px;
}

.btn-social{
  img{
    width:70px;
    height:70px;
  }
}



@keyframes animation-blink {
 0% {
  left:-100%
 }
 100%,
 20% {
  left:100%;
  margin-left:5rem
 }
}

.has-animation-blink{
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  border-radius: 50%;
  overflow: hidden;

  &::after{
    content: "";
    display: block;
    width: 1rem;
    height: 100%;
    background: rgba(255,255,255,0.5);
    opacity: .9;
    position: absolute;
    left: -100%;
    top: 0;
    z-index: 1;
    transform: rotate(45deg) scale(2);
    animation: 3s linear 0s infinite normal animation-blink;
  }
}
</style>
