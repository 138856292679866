<template>
  <div class="modal fade" id="ModalCallback" tabindex="-1" aria-labelledby="ModalCallback_label"  aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="ModalCallback_label">{{param.title}}</h5>
          <button
            type="button"
            class="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">



          <transition name="fade" mode="out-in">
            <div key="1" v-if="!sended">
              <div class="form-outline mb-3">
                <transition name="fade" mode="out-in">
                  <span class="trailing text-danger" v-if="errors.indexOf('name')>=0">Ошибка!</span>
                </transition>
                <input type="text" id="ModalCallback_name" class="form-control" v-model="fields.name" />
                <label class="form-label" for="ModalCallback_name">Ваше имя</label>
                <div class="form-notch">
                  <div class="form-notch-leading"></div>
                  <div class="form-notch-middle"></div>
                  <div class="form-notch-trailing"></div>
                </div>
              </div>

              <div class="form-outline mb-3">
                <transition name="fade" mode="out-in">
                  <span class="trailing text-danger" v-if="errors.indexOf('email')>=0">Ошибка!</span>
                </transition>
                <input type="text" id="ModalCallback_email" class="form-control" v-model="fields.email" />
                <label class="form-label" for="ModalCallback_email">Электронная почта</label>
                <div class="form-notch">
                  <div class="form-notch-leading"></div>
                  <div class="form-notch-middle"></div>
                  <div class="form-notch-trailing"></div>
                </div>
              </div>

              <div class="form-outline mb-3">
                <transition name="fade" mode="out-in">
                  <span class="trailing text-danger" v-if="errors.indexOf('phone')>=0">Ошибка!</span>
                </transition>
                <input type="text" id="ModalCallback_phone" class="form-control" v-model="fields.phone"/>
                <label class="form-label" for="ModalCallback_phone">Телефон *</label>
                <div class="form-notch">
                  <div class="form-notch-leading"></div>
                  <div class="form-notch-middle"></div>
                  <div class="form-notch-trailing"></div>
                </div>
              </div>
            </div>


            <div key="1" v-if="sended">
              <p>Ваша заявка получена. С Вами свяжется наш менеджер в рабочие дни с 9 до 17 часов.</p>
            </div>

          </transition>
          
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary ripple-surface" data-mdb-dismiss="modal">
            Закрыть
          </button>
          <button type="button" class="btn btn-primary ripple-surface" @click="send()" v-if="!sended">Отправить</button>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ModalCallback',
  props: {
    param: Object
  },
  data: ()=>{
    return {
      sended: false,
      errors:[],
      fields:{
        name:null,
        email:null,
        phone:null
      }
    }
  },
  methods:{
      validEmail: function (email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(email);
      },
      validPhone: function (phone) {
          var phone_numbers = phone.replace(/\D/g,'')
          return (phone_numbers.length === 11) ? true : false
      },
      validForm: function(){
      this.errors = [];

        if (!this.fields.phone) {
            this.errors.push('phone')
        } else if (!this.validPhone(this.fields.phone)) {
          this.errors.push('phone')
        }   

        if (this.fields.email !== null && this.fields.email.length > 0 && !this.validEmail(this.fields.email)) {
          this.errors.push('email')
        }

        return (this.errors && this.errors.length) ? false : true
      },
      send: function(){
        if (this.validForm()){
          let d = Object.assign({}, this.fields, this.param)
          axios.post('/send_order/', d, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
          }).then(r=>{
            this.$parent.$parent.load = false;
            if (r.data.status == 'success') {
              this.sended = true
              this.$emit('sended', true)
              // eslint-disable-next-line no-undef
              ym(73424164,'reachGoal','success_callback')
            }
          })
        }
      }
  }
}
</script>

<style scoped lang="scss">

</style>
